.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    min-width: 100px;
    min-height: 40px;
    padding: 10px;
    border: 1px solid #8e36ff;
    border-radius: 9999px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-family: 'SF Pro Display', sans-serif;
    color: #ebe9ef;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  
  .button.filled {
    background-color: #8e36ff;
    border: none;
  }
  
  .button.filled:hover {
    background-color: #6200ea;
  }
  
  .button.outlined {
    background-color: transparent;
    color: #8e36ff;
    border: 1px solid #8e36ff;
  }
  
  .button.outlined:hover {
    background-color: #8e36ff;
    color: #f8f8fa;
  }
  
  .button-disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #e0e0e0;
    border-color: #d0d0d0;
  }
  
  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #f8f8fa;
    width: 24px;
    height: 24px;
  }
  
  .icon-left {
    margin-right: 4px;
  }
  
  .icon-right {
    margin-left: 4px;
  }
  
  .icon svg {
    width: 16px;
    height: 16px;
  }
  
  .button.outlined .icon {
    color: #8e36ff;
  }
  
  .button.outlined:hover .icon {
    color: #f8f8fa;
  }
  
  .Buttontext {
    display: inline-block;
  }